import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public url = "https://api-quote.sparkleweb.co.in/api/Account/";

  constructor(private _http: HttpClient) { }
  resetPassword = 'resetPassword';

  postresetPassword(data: any) {
    return this._http.post(this.url + this.resetPassword, data);
  }
}
